var firstTime = false;

var category = '';
var model = '';
var vehicleid = '';
var language = '';
var version_name = '';

function adobeAnalyticCode(allData) {

    // console.log(allData);
    category = allData.category;
    model = allData.model;
    vehicleid = allData.vehicleid;
    language = allData.language;
    version_name = allData.version_name;

    // && allData.new_insurance != null 
    if(allData.new_downPayment != null 
        && allData.new_profit != null
        && allData.new_year != null
        && allData.new_monthlyPayment != null
        && allData.new_selectedCarPrice != null)
    {
        var new_downPayment = allData.new_downPayment;
        // var new_insurance = allData.new_insurance;
        var new_profit = allData.new_profit;
        var new_year = allData.new_year;
        var new_monthlyPayment = allData.new_monthlyPayment;
        var new_selectedCarPrice =allData.new_selectedCarPrice;
    }
   
    var s;
    // var host = window.location.hostname;
    // var url = window.location.hostname.split("/");
    var referer = document.referrer;
    referer.replace(".html", "");
    // var previous_page = referer.split("/");
    // var startform = false;
    // var language = "en";
    var full_url = window.location.href;
    var device = "medium";
    var screen_resolution = window.screen.width + "x" + window.screen.height;
    // var screen_size = window.innerWidth + "x" + window.innerHeight;
    var loading_time = "";
    var today = new Date();
    var time = today.toLocaleTimeString().replace("AM", "").replace("PM", "");
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    var time_stamp = today + " " + time;
    // var dt = new Date(new Date().toString().split("GMT")[0] + " UTC").toISOString();
    var date = new Date();
    var time_stamp2 = date.toISOString();
    var platform = "Helios|" + time_stamp2;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      device = "small";
    }
    var before_loadtime = new Date().getTime();
    window.onload = Pageloadtime;

    function Pageloadtime() {
      var aftr_loadtime = new Date().getTime();
      // Time calculating in seconds
      var pgloadtime = (aftr_loadtime - before_loadtime) / 1000;
      loading_time = pgloadtime.toFixed(1) + " seconds";
      //document.getElementById("loadtime").innerHTML = "Pgae load time is <font color='red'><b>" + pgloadtime + "</b></font> Seconds";
    }

    s = window._satellite.getToolsByType("sc")[0].getS();
    s.account = "nissankwprod"; //staging-nissankwdstaging
    s.currencyCode = "KWD"; // Currency Code
    s.pageName = "finance-calculator"; //pagename
    s.eVar1 = "finance-calculator";
    s.prop1 = "finance-calculator"; //$scope.getPreviousPage();
    s.eVar2 = "finance-calculator|"; //sub section
    s.prop2 = "finance-calculator|"; //sub section
    s.eVar3 = "finance-calculator||"; //Sub sub section
    s.prop3 = "finance-calculator||"; //Sub sub section
    s.eVar4 = category; //category eg: PASSENGER CARS
    s.prop4 = category; //category eg: PASSENGER CARS
    s.eVar5 = model; //model eg: MAXIMA
    s.prop5 = model; //model eg: MAXIMA 
    s.eVar6 = vehicleid; //vehicleid eg: A36 (grade code) 
    s.prop6 = vehicleid; //vehicleid eg: A36 (grade code)
    s.eVar8 = language; //Language
    s.prop8 = language; //Language
    s.eVar9 = "KW"; // Country Code
    s.prop9 = "KW"; // Country Code
    s.eVar10 = "nissan"; //Brand
    s.prop10 = "nissan"; //Brand
    s.eVar12 = "finance-calculator"; //$scope.getPreviousPage(); //Brand

    // && allData.new_insurance != null
    if(allData.new_downPayment != null  
      && allData.new_profit != null
      && allData.new_year != null
      && allData.new_monthlyPayment != null
      && allData.new_selectedCarPrice != null)
    {
      s.eVar14 = allData.button_name+"|finance-request|finance-calculator|bottom"; //Button Click
      s.prop14 = allData.button_name+"|finance-request|finance-calculator|bottom"; //Button Click
      s.eVar31 ="financing option|" + new_downPayment + "|" + new_profit + new_year + "|" + new_monthlyPayment + "|" + new_selectedCarPrice; ////Button Click
    }
    
    if(allData.version_name != null){
      s.eVar55 = version_name; //category eg: MAXIMA SV
    }

    s.eVar21 = "vehicle"; //page type
    s.eVar29 = "Finance Tool"; //Vehicle : page name
    s.eVar35 = "logged out"; //login status
    s.eVar40 = loading_time; //pre loading time
    s.prop40 = loading_time; //pre loading time
    s.eVar44 = device + "|" + screen_resolution; //device & screen resolution
    s.eVar45 = device; //screen size
    s.prop45 = "Nissan Helios AMI"; //screen size
    s.prop46 = "nissan KW"; //form name with Country Code
    s.eVar46 = ""; //form name
    s.prop52 = s.marketingCloudVisitorID; //visitor ID
    s.eVar54 = full_url; //component id
    s.prop54 = full_url; //component id
    s.eVar61 = platform + "|" + language; //[platform]
    s.eVar63 = time_stamp; //date and time
    s.eVar64 = "finance-calculator";
    s.eVar97 = "finance-calculator"; //scroll
    s.events = "";

    if (!firstTime) {
      s.events = "event145";
      firstTime = true;
    }

    s.ta();

}

export { adobeAnalyticCode };
